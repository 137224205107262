import { FilterObject, LoadValidationRulesResult, ValidationRule } from 'state-domains/domain';
import { Observable, of as observableOf } from 'rxjs';
import { buildConfigurationValidationRulesUrlWithFilters } from 'state-domains/network';
import { convertToCamel, sendRetrieveRequest } from 'state-domains/utils';
import { switchMap } from 'rxjs/operators';
import { AjaxResponse } from 'rxjs/ajax';

export const loadConfigurationValidationRules = (filters: FilterObject[]): Observable<any> => {
    const url = buildConfigurationValidationRulesUrlWithFilters(filters);
    const obs = sendRetrieveRequest(url);
    return obs.pipe(
        switchMap(({ response }: AjaxResponse<LoadValidationRulesResult>) => {
            const items = convertToCamel<ValidationRule>(response.ValidationRule);
            return observableOf(items);
        }),
    );
};
